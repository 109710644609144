<template>
    <div>
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('send_mail')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="form-group row">
                        <div class="col-6">
                            <label for="title">
                                {{$t('title')}}
                            </label>
                            <input id="title" type="text" class="form-control" v-model="data.title" :class="validation && validation.title ? 'is-invalid' : ''" :placeholder="$t('title')">
                            <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.title[0] }}
                          </span>
                        </div>
                        <div class="col-6 mb-5">
                            <label for="email">
                                {{$t('email')}}
                            </label>
                            <input id="email" type="email" class="form-control" v-model="data.email" :class="validation && validation.email ? 'is-invalid' : ''" :placeholder="$t('email')">
                            <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.email[0] }}
                          </span>
                        </div>

                        <div class="col-6">
                            <label for="cc">
                                {{$t('cc')}}
                            </label>
                            <input id="cc" type="email" class="form-control" v-model="data.cc" :class="validation && validation.cc ? 'is-invalid' : ''" :placeholder="$t('cc')">
                            <span v-if="validation && validation.cc" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.cc[0] }}
                          </span>
                        </div>
                        <div class="col-6">
                            <label for="bcc">
                                {{$t('bcc')}}
                            </label>
                            <input id="bcc" type="email" class="form-control" v-model="data.bcc" :class="validation && validation.bcc ? 'is-invalid' : ''" :placeholder="$t('bcc')">
                            <span v-if="validation && validation.bcc" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.bcc[0] }}
                          </span>
                        </div>

                        <div class="col-lg-6 mt-5">
                            <label>{{$t('content')}}</label>
                            <vue-editor ref="editor" v-model="data.content"></vue-editor>
                        </div>

                        <div class="col-lg-6 mt-5">
                            <label>{{$t('additional_info')}}</label>
                            <div v-if="customer_data">
                                <label>{{$t('customer_info')}}: </label>
                                <div class="d-flex">
                                    <b-badge class="m-2 cursor-pointer" v-if="customer_data.fullname" @click="setInEditor(customer_data.fullname)">{{customer_data.fullname}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="customer_data.email" @click="setInEditor(customer_data.email)">{{customer_data.email}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="customer_data.mobile" @click="setInEditor(customer_data.mobile)">{{customer_data.mobile}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="customer_data.address_1" @click="setInEditor(customer_data.address_1)">{{customer_data.address_1}}</b-badge>
                                </div>
                            </div>
                            <div>
                                <label>{{$t('company_info')}}: </label>
                                <div class="d-flex">
                                    <b-badge class="m-2 cursor-pointer" v-if="company_data.business_name" @click="setInEditor(company_data.business_name)">{{company_data.business_name}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="company_data.email" @click="setInEditor(company_data.email)">{{company_data.email}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="company_data.mobile" @click="setInEditor(company_data.mobile)">{{company_data.mobile}}</b-badge>
                                    <b-badge class="m-2 cursor-pointer" v-if="company_data.address_1" @click="setInEditor(company_data.address_1)">{{company_data.address_1}}</b-badge>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 mt-5">
                            <div class="form-group row">
                                <div class="col-lg-12 pt-8">
                                    <h5>{{$t('customers.attachments')}}</h5>
                                </div>
                                <div class="col-lg-12 mt-10">
                                    <upload-file
                                            @file="listenerAttachment"
                                            :inner-id="'attachment'"
                                            :placeholder="$t('customers.upload_attachment')"
                                            :upload="dir_upload"
                                            :start-link="'base'"
                                            :use-preview="false"
                                            v-bind:valueprop="{name:'attachment',path: attachment_url}" v-if="reloadUploadAttachment">
                                    </upload-file>
                                </div>
                                <div class="col-lg-12 mt-5">
                                    <div class="form-group">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>{{ $t('customers.file')}}</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(att, index) in attachment_list" :key="index">
                                                <td>
                                                    <a target="_blank" :href="att.attachment_url" class="btn btn-link">{{ $t('customers.file_link')}} ({{att.attachment}})</a>
                                                </td>
                                                <td class="text-center">
                                                    <v-icon class="text-danger" @click="removeAttachmentFromList(index)">mdi-delete</v-icon>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-6 mb-8 text-center">
                            <button type="button" class="btn btn-primary btn-block" @click="save">{{ $t('send') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import {VueEditor } from "vue2-editor";

    export default {
        components: {
            VueEditor
        },
        name: "form-cutomer",
        data() {
            return {
                mainRoute: 'base/send/custom/email',
                getRouteInvoice: 'sales/sales_invoices',
                getRoutePaymentSales: 'sales/payment_sales_invoices',
                getRoutePayableCheque : 'chequescycle/payable-cheques',
                getRouteReceivableCheque : 'chequescycle/receivable-cheques',
                mainRouteDependency: 'base/dependency',
                getRouteCompany: 'settings/company/getCompany',
                dir_upload: 'emails',

                data: {
                    title: "",
                    email: "",
                    bcc: "",
                    cc: "",
                    content: "",
                    type_type: this.$route.params.type ? this.$route.params.type : null,
                    type_id: this.$route.params.type_id,
                },
                isDisabled: true,
                data_type: [],
                reloadUploadAttachment: true,
                attachment_list: [],
                attachment_url: null,
                isEditing: false,
                validation: null,
                tags_list:[],
                customer_data:[],
                company_data:[],

            };
        },
        watch: {},
        methods: {
            save() {
                this.create();
            },

    create() {
        this.reloadUploadAttachment = false;
        ApiService.post(`base/send/custom/email`, {
            attachments: this.attachment_list,
            ...this.data,
        }).then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.go();
            })
            .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
                this.$errorAlert(error);
            });
            this.attachment_url = null;
            this.reloadUploadAttachment = true;
        },
  


            listenerAttachment(event) {
                if (event) {
                    this.addAttachmentToList(event.pathDB, event.name);
                }
            },
            addAttachmentToList(attachment_url, attachment) {
                this.reloadUploadAttachment = false;
                this.attachment_url = null;
                this.attachment_list.push({id: null, attachment: attachment, attachment_url: attachment_url});
                this.reloadUploadAttachment = true;
            },
            removeAttachmentFromList(index) {
                this.attachment_list.splice(index, 1);
            },

            async getData() {
                var base_url;
                switch (this.data.type_type) {
                    case 'SalesInvoice':
                        base_url = this.getRouteInvoice;
                        break;
                    case 'PaymentSales':
                        base_url = this.getRoutePaymentSales;
                        break;
                    case 'PayableCheque':
                        base_url = this.getRoutePayableCheque;
                        break;
                    case 'ReceivableCheque':
                        base_url = this.getRouteReceivableCheque;
                        break;
                    default:
                        break;
                }

                ApiService.get(`${base_url}/${this.data.type_id}`).then((response) => {
                    this.data_type = response.data.data;
                    this.customer_data = response.data.data.customer;
                    this.data.email = this.customer_data.email;
                });
            },
            async getCompanyData() {
                ApiService.get(this.getRouteCompany).then((response) => {
                    this.company_data = response.data.data;
                });
            },

            addTag(newTag) {
                this.data.additional_info.push(newTag)
                this.tags_list.push(newTag)
            },

            setInEditor(newTag){
                const quill = this.$refs.editor.quill; // take quill
                let range = quill.getSelection(true);
                if (range.index){
                    quill.insertText(range.index, newTag);
                }

            }
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("send_mail")}]);
            this.getData();
            this.getCompanyData();
        },
    };
</script>
